<template>
    <div class="container">
        <div class="container-fluid pt-4">
            <div class="row d-flex justify-content-center align-items-center">
                <div class="col-md-9 col-lg-6 col-xl-5">
                    <img src="@/assets/bg-login.png" class="img-fluid">
                </div>
                <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <form @submit.prevent="login()">
                        <h5 class="text-center fw-bold my-4">Acesso ao Sistema</h5>
                        <div class="row d-flex justify-content-center">
                            <div class="col-12 mb-3">
                                <label class="form-label m-0 fw-bold">Usuário</label>
                                <input v-model="username" type="text" class="form-control">
                            </div>
                            <div class="col-12 mb-3">
                                <label class="form-label m-0 fw-bold">Senha</label>
                                <input v-model="password" type="password" class="form-control">
                            </div>
                            <div class="col-12 d-flex justify-content-center mb-3">
                                <vue-recaptcha ref="recaptcha" :sitekey="'6LeCmDweAAAAAOFMfjwg-rU4OKOquLvTJ2lcd0LM'" @verify="verifyRecaptcha"></vue-recaptcha>
                            </div>
                            <div class="col-12 mb-3">
                                <button ref="btnLogin" type="submit" class="btn btn-success w-100">Acessar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    components: {
        VueRecaptcha
    },

    data() {
        return {
            username: '',
            password: '',
            recaptcha: ''
        }
    },

    methods: {
        async login() {
            this.$refs.btnLogin.innerText = 'Verificando...'
            this.$refs.btnLogin.disabled = true
            this.$parent.hideAlert()

            await this.$http.post('auth/login', {
                username: this.username,
                password: this.password,
                recaptcha: this.recaptcha
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    localStorage.setItem('_token', response.data.access_token)
                    this.$router.push('/painel')
                }
                else
                {
                    this.$parent.showAlert('error', response.data.message)
                }
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível realizar o acesso ao sistema')
            })
            .finally(() => {
                this.$refs.btnLogin.innerText = 'Acessar'
                this.$refs.btnLogin.disabled = false
                this.$refs.recaptcha.reset()
                this.recaptcha = ''
            })
        },

        verifyRecaptcha(response){
            this.recaptcha = response
        }
    }
}
</script>

<style>
.box {
    width: 360px;
}
</style>