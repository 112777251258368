<template>
    <div class="container-fluid p-0">
        <div class="container pt-2">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mt-2">
                    <li class="breadcrumb-item">
                        <router-link class="router-link text-dark" to="/">Início</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        Produtos
                    </li>
                    <li class="breadcrumb-item">
                        Embalagens
                    </li>
                </ol>
            </nav>

            <div v-if="products == ''" class="d-flex justify-content-center align-items-center border rounded p-5 mt-3">Nenhum produto foi encontrado</div>

            <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4 mb-5">
                <Product v-for="(product, n) in products"
                    :key="n"
                    :id="product.id"
                    :title="product.title"
                    :price="product.price"
                    :isSale="product.isSale"
                    :priceSale="product.priceSale"
                    :image="product.image"
                />
            </div>
            <Pagination :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="productsLoad"></Pagination>
        </div>
        <Footer />
    </div>
</template>

<script>
import Product from '@/components/Product'
import Pagination from 'advanced-laravel-vue-paginate'
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'
import Footer from '@/components/Footer'

export default {
    components: {
        Product,
        Pagination,
        Footer
    },

    data() {
        return {
            products: [],
            pagination: {},
        }
    },

    methods: {
        async productsLoad(page) {
            if(typeof page === 'undefined') {
                page = 1;
            }

            this.products = []
            this.$parent.loading = true

            await this.$http.get('products/type?type=embalagens&page=' + page).then((response) => {
                this.pagination = response.data
                this.products = response.data.data
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível carregar os produtos')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        }
    },

    created() {
        this.productsLoad()
    }
}
</script>

<style>
</style>