import http from '@/services/axios'

export default {
    auth(to, from, next) {
        http.get('auth/me', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('_token')
            }
        })
        .then((response) => {
            if(response.data.username == undefined) {
                next({
                    path: '/login'
                })
            }
            else
            {
                next()
            }
        })
        .catch(() => {
            console.log('Ocorreu um erro inesperado')
        })
    },

    guest(to, from, next) {
        http.get('auth/me', {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('_token')
            }
        })
        .then((response) => {
            if(response.data.username == undefined) {
                next()
            }
            else
            {
                next({
                    path: '/painel'
                })
            }
        })
        .catch(() => {
            console.log('Ocorreu um erro inesperado')
        })
    }
}