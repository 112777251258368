<template>
    <div class="d-flex border my-3 rounded bg-white shadow p-2">
        <div class="flex-shrink-0 border-end d-flex align-items-center p-1 position-relative">
            <button @click="del()" type="button" class="btn btn-danger btn-sm position-absolute top-50 start-0 translate-middle d-flex align-items-center">
                <span class="material-icons md-15">delete</span>
            </button>
            <img :src="$http.defaults.baseURL2 + '/storage/' + image" width="70" height="70">
        </div>
        <div class="flex-grow-1 ms-2 pe-1">
            <p class="title-product fw-light text-nowarp">{{ title }}</p>
            <div class="row m-0">
                <div class="col-4 text-center">
                    <div class="fst-italic fs-aux">Quanti.</div>
                    <div class="border mt-3 rounded bg-light fw-bold">{{ amount }}</div>
                    <div class="d-flex justify-content-between mt-1">
                        <button v-if="amount == 0" type="button" class="btn-sm p-0 d-flex align-items-center no-outline bg-transparent text-secondary rounded border-0" disabled>
                            <span class="material-icons md-18 hover fw-bold">remove</span>
                        </button>
                        <button v-else @click="sub()" type="button" class="btn-sm p-0 d-flex align-items-center no-outline bg-transparent text-danger rounded border-0">
                            <span class="material-icons md-18 hover fw-bold">remove</span>
                        </button>
                        <button @click="add()" type="button" class="btn-sm p-0 d-flex align-items-center no-outline bg-transparent text-success rounded border-0">
                            <span class="material-icons md-18 hover fw-bold">add</span>
                        </button>
                    </div>
                </div>
                <div class="col-4 align-items-center text-center">
                    <div class="fst-italic fs-aux">Preço</div>
                    <div class="fw-bold fs-aux mt-3">{{ priceShow }}</div>
                </div>
                <div class="col-4 align-items-center text-center">
                    <div class="fst-italic fs-aux">Total</div>
                    <div class="fw-bold fs-aux mt-3 text-success">{{ priceSumShow }}</div>
                </div>
            </div>
        </div>

        <input class="form-control w-auto d-none" v-model="priceShow" v-money="money" type="text">
        <input class="form-control w-auto d-none" v-model="priceSumShow" v-money="money" type="text">
    </div>
</template>

<script>
import {VMoney} from 'v-money'

export default {
    props: ['id', 'title', 'price', 'image', 'amount'],

    data() {
        return {
            priceShow: this.price,
            priceSumShow: 0,

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    directives: {
        money: VMoney
    },

    methods: {
        sub() {
            this.$store.commit('subProduct', this.id)
        },

        add() {
            this.$store.commit('addProduct', this.id)
        },

        del() {
            this.$store.commit('delProduct', this.id)
            this.$parent.$parent.loading = true
            this.$store.commit('cartLoad')

            setTimeout(() => {
                this.$parent.$parent.loading = false
            }, 1000)
        },
    },

    watch: {
        amount() {
            this.priceSumShow = (this.price * this.amount).toFixed(2)
        }
    },

    created() {
        this.priceSumShow = (this.price * this.amount).toFixed(2)
    }
}
</script>

<style>
.title-product {
    height: 40px;
    font-size: 14px;
    overflow: hidden;
}

.fs-aux{
    font-size: 13px;
}

.amount-card{
    width: 50px;
}
</style>