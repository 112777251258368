<template>
    <div class="container py-2 my-2">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mt-2">
                <li class="breadcrumb-item">
                    <router-link class="router-link text-dark" to="/">Início</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="router-link text-dark" to="/painel">Painel</router-link>
                </li>
                <li class="breadcrumb-item">
                    Produtos
                </li>
            </ol>
        </nav>

        <form @submit.prevent="searchProduct()">
            <div class="row d-flex justify-content-end m-0 mb-2">
                <div class="input-group w-auto p-0">
                    <input v-model="search" type="text" class="form-control" placeholder="Buscar por...">
                    <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li @click="searchTypeProduct('Todos')"><a class="dropdown-item hover">Todos</a></li>
                        <li @click="searchTypeProduct('Promoções')"><a class="dropdown-item hover">Promoções</a></li>
                        <li @click="searchTypeProduct('Ativados')"><a class="dropdown-item hover">Ativados</a></li>
                        <li @click="searchTypeProduct('Desativados')"><a class="dropdown-item hover">Desativados</a></li>
                        <li><hr class="dropdown-divider"></li>
                        <li v-for="(type, n) in listTypes" :key="n" @click="searchTypeProduct(type)"><a class="dropdown-item hover">{{ type }}</a></li>
                    </ul>
                    <button type="submit" class="btn btn-secondary rounded-end me-3">Buscar</button>
                    <button type="button" class="btn btn-success rounded w-auto" data-bs-toggle="modal" data-bs-target="#modalAdd">Adicionar</button>
                </div>
            </div>
        </form>

        <div v-if="searchShow != ''" class="mt-3">Você esta busando por: <b>{{ searchShow }}</b></div>
        <div v-if="products == ''" class="d-flex justify-content-center align-items-center border rounded p-5 mt-3">Nenhum produto foi encontrado</div>

        <div class="row mt-0 g-2 mb-4">
            <ProductTable v-for="(product, n) in products"
                :key="n"
                :id="product.id"
                :title="product.title"
                :price="product.price"
                :isSale="product.isSale"
                :priceSale="product.priceSale"
                :cod="product.cod"
                :type="product.type"
                :status="product.status"
                :image="product.image"
            />
        </div>

        <div class="modal fade" id="modalAdd" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="addProduct()">
                        <div class="modal-header">
                            <h5 class="modal-title">Adicionar</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Produto</label>
                                    <input v-model="productAdd.title" type="text" class="form-control text-uppercase">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label m-0 fw-bold">Preço</label>
                                    <input v-model="productAdd.price" v-money="money" type="text" class="form-control" inputmode="numeric">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label m-0 fw-bold">Código</label>
                                    <input v-model="productAdd.cod" type="text" class="form-control" inputmode="numeric" @keydown.enter.prevent>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Categoria</label>
                                    <select v-model="productAdd.type" class="form-select">
                                        <option selected disabled>Selecione</option>
                                        <option v-for="(type, n) in listTypes" :key="n" :value="type">{{ type }}</option>
                                    </select>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Situação</label>
                                    <select v-model="productAdd.status" class="form-select">
                                        <option value="1">Ativado</option>
                                        <option value="0">Desativado</option>
                                    </select>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Imagem</label>
                                    <input @change="onImageAdd" ref="imageAdd" class="form-control" type="file">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button ref="btnCloseAdd" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button ref="btnAdd" type="submit" class="btn btn-success">Adicionar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <Pagination v-if="search != ''" :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="searchProduct"></Pagination>
        <Pagination v-else-if="searchType != ''" :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="typeProduct"></Pagination>
        <Pagination v-else :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="productsLoad"></Pagination>
    </div>
</template>

<script>
import ProductTable from '@/components/ProductTable'
import {VMoney} from 'v-money'
import Pagination from 'advanced-laravel-vue-paginate'
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'

export default {
    components: {
        ProductTable,
        Pagination
    },

    data() {
        return {
            listTypes: ['Doces', 'Chocolates', 'Biscoitos', 'Salgadinhos', 'Descartáveis', 'Utensílios', 'Decorações', 'Balões', 'Confeitaria', 'Embalagens', 'Bebidas', 'Brinquedos', 'Vestimentas', 'Cosméticos', 'Diversos'],
            
            products: [],
            search: '',
            searchType: '',
            searchShow: '',

            productAdd: {
                title: '',
                price: '',
                cod: '',
                type: 'Selecione',
                status: 1,
                image: ''
            },

            pagination: {},

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    directives: {
        money: VMoney
    },

    methods: {
        onImageAdd(event) {
            this.productAdd.image = event.target.files[0]
        },

        async productsLoad(page) {
            if(typeof page === 'undefined') {
                page = 1;
            }

            this.products = []
            this.$parent.loading = true
            this.search = ''
            this.searchType = ''
            this.searchShow = ''

            await this.$http.get('panel/products?page=' + page, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.pagination = response.data
                this.products = response.data.data
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível carregar os produtos')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        async addProduct() {
            const formData = new FormData()
            formData.append('title', this.productAdd.title)
            formData.append('price', this.productAdd.price)
            formData.append('cod', this.productAdd.cod)
            formData.append('type', this.productAdd.type)
            formData.append('status', this.productAdd.status)
            formData.append('image', this.productAdd.image)

            this.$refs.btnAdd.innerText = 'Verificando...'
            this.$refs.btnAdd.disabled = true
            this.$parent.hideAlert()

            await this.$http.post('panel/products/add', formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    this.$parent.showAlert('success', response.data.message)
                    this.clearFieldsAdd()
                    this.productsLoad()
                }
                else
                {
                    this.$parent.showAlert('error', response.data.message)
                }
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível adicionar o produto')
            })
            .finally(() => {
                this.$refs.btnAdd.innerText = 'Adicionar'
                this.$refs.btnAdd.disabled = false
            })
        },

        async searchProduct(page) {
            const formData = new FormData()
            formData.append('search', this.search)

            if(typeof page === 'undefined') {
                page = 1;
            }

            this.products = []
            this.$parent.loading = true
            this.searchType = ''

            await this.$http.post('panel/products/search?page=' + page, formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.pagination = response.data
                this.products = response.data.data
                this.searchShow = this.search
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível fazer a busca')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        async typeProduct(page) {
            const formData = new FormData()
            formData.append('type', this.searchType)

            if(typeof page === 'undefined') {
                page = 1;
            }

            this.products = []
            this.$parent.loading = true
            this.search = ''

            await this.$http.post('panel/products/search/type?page=' + page, formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.pagination = response.data
                this.products = response.data.data
                this.searchShow = this.searchType
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível fazer a busca')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        searchTypeProduct(type) {
            this.searchType = type
            this.typeProduct()
        },

        clearFieldsAdd() {
            this.productAdd.title = ''
            this.productAdd.price = 0
            this.productAdd.cod = ''
            this.productAdd.type = 'Selecione'
            this.productAdd.status = 1
            this.productAdd.image = ''
            this.$refs.imageAdd.value = null
            this.$refs.btnCloseAdd.click()
        }
    },

    created() {
        this.productsLoad()
    }
}
</script>

<style>
</style>