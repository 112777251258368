<template>
    <div class="container-fluid p-0">
        <div class="container pt-2">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb mt-2">
                    <li class="breadcrumb-item">
                        <router-link class="router-link text-dark" to="/">Início</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        Finalizar Compra
                    </li>
                </ol>
            </nav>

            <div v-show="success != ''" class="alert alert-success" role="alert">
                <h4 class="alert-heading">Pedido realizado!</h4>
                <p>{{ success }}</p>
                <hr>
                <p class="mb-0"><a href="https://wa.me/5515981572201?text=Olá,%20Acabei%20de%20finalizar%20um%20pedido%20no%20site!" target="_blank" class="text-success fw-bold">Clique aqui</a> para entrar em contato pelo WhatsApp de uma forma mais prática</p>
            </div>

            <div v-show="success == ''" class="row m-0">
                <div class="col-lg-8">
                    <div v-if="products == ''" class="d-flex justify-content-center align-items-center border rounded p-5 mt-3">Seu carrinho esta vazio</div>

                    <ProductCart v-for="(product, n) in products"
                        :key="n"
                        :id="product.id"
                        :title="product.title"
                        :price="product.price"
                        :image="product.image"
                        :amount="product.amount"
                    />
                </div>
                <div class="col-lg-4 p-1">
                    <div class="p-3 bg-finish border rounded shadow">
                        <div class="bg-white border shadow rounded p-3">
                            <h5 class="text-center fw-bold">Meu Carrinho</h5>
                            <div class="d-flex justify-content-between mt-3">
                                <b>Quantidade:</b> {{ size }} itens
                            </div>
                            <div class="d-flex justify-content-between mt-1">
                                <b>Subtotal:</b> {{ priceShow }}
                            </div>
                            <div class="d-flex justify-content-between mt-1">
                                <b>Frete:</b> {{ priceShipShow }}
                            </div>
                            <div class="d-flex justify-content-between mt-1 rounded bg-dark text-white fw-bold p-1">
                                <b>Total:</b> {{ priceTotalShow }}
                            </div>
                        </div>

                        <div class="bg-white border shadow rounded p-3 mt-3">
                            <h5 class="text-center fw-bold">Informações</h5>
                            <div class="row">
                                <div class="col-12 my-3">
                                    <label class="form-label m-0 fw-bold">Nome Completo<i class="text-danger">*</i></label>
                                    <input v-model="infos.name" type="text" class="form-control text-uppercase">
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Telefone/Celular<i class="text-danger">*</i></label>
                                    <input v-model="infos.phone" v-mask="'(##) #####-####'" type="text" class="form-control text-uppercase" inputmode="numeric">
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">CPF<i class="text-danger">*</i></label>
                                    <input v-model="infos.cpf" v-mask="'###.###.###-##'" type="text" class="form-control text-uppercase" inputmode="numeric">
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Email</label>
                                    <input v-model="infos.email" type="text" class="form-control text-lowercase">
                                </div>
                                <div class="d-flex justify-content-around">
                                    <div class="form-check form-check-inline">
                                        <input v-model="ship" class="form-check-input" type="radio" value="pickup">
                                        <label class="form-check-label">Retirar na Loja</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input v-model="ship" class="form-check-input" type="radio" value="ship">
                                        <label class="form-check-label">Entrega</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-show="ship == 'ship'" class="bg-white border shadow rounded p-3 mt-3">
                            <h5 class="text-center fw-bold">Frete e Pagamento</h5>
                            <div class="row">
                                <div class="col-12 my-3">
                                    <form @submit.prevent="shipping()">
                                        <label class="form-label m-0 fw-bold">CEP<i class="text-danger">*</i></label>
                                        <div class="input-group">
                                            <input v-model="infos.code" v-mask="'#####-###'" ref="inputCode" type="text" class="form-control" inputmode="numeric">
                                            <button ref="btnCalc" type="submit" class="btn btn-primary rounded-end">Calcular</button>
                                            <button @click="clearFieldsShipping()" type="button" class="btn btn-secondary d-flex align-items-center rounded ms-1">
                                                <span class="material-icons md-18 hover fw-bold">refresh</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div v-if="infos.street != ''" class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Logradouro</label>
                                    <div class="input-group">
                                        <input v-model="infos.street" type="text" class="form-control w-auto" readonly>
                                        <input v-model="infos.number" type="text" class="form-control text-center p-1" placeholder="Nº" inputmode="numeric">
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Complemento</label>
                                    <input v-model="infos.complement" type="text" class="form-control text-uppercase">
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Bairro</label>
                                    <input v-model="infos.district" type="text" class="form-control" readonly>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Cidade</label>
                                    <div class="input-group">
                                        <input v-model="infos.city" type="text" class="form-control w-auto" readonly>
                                        <input v-model="infos.state" type="text" class="form-control" readonly>
                                    </div>
                                </div>

                                <label class="form-label m-0 fw-bold">Frete<i class="text-danger">*</i></label>
                                <div v-if="infos.city == 'Sorocaba'" class="col-12 mb-3">
                                    <div class="form-check d-flex align-items-center border rounded py-2">
                                        <input v-model="infos.ship" class="form-check-input ms-4 me-auto" type="radio" value="Delivery">
                                        <div class="text-end me-4">
                                            <b>Entregador (Sorocaba)</b>
                                            <p class="m-0">R$ 15,00</p>
                                            <p class="m-0">Entrega no dia</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="form-check d-flex align-items-center border rounded py-2">
                                        <input v-model="infos.ship" class="form-check-input ms-4 me-auto" type="radio" value="Pac">
                                        <div class="text-end me-4">
                                            <b>Pac</b>
                                            <p class="m-0">{{ pacPriceShow }}</p>
                                            <p class="m-0">{{ infos.pacDays }} dias úteis</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <div class="form-check d-flex align-items-center border rounded py-2">
                                        <input v-model="infos.ship" class="form-check-input ms-4 me-auto" type="radio" value="Sedex">
                                        <div class="text-end me-4">
                                            <b>Sedex</b>
                                            <p class="m-0">{{ sedexPriceShow }}</p>
                                            <p class="m-0">{{ infos.sedexDays }} dias úteis</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Pagamento<i class="text-danger">*</i></label>
                                    <select v-model="infos.payment" class="form-select" aria-label="Default select example">
                                        <option value="" selected disabled>Selecione</option>
                                        <option value="Pix">Pix</option>
                                        <option value="Crédito">Crédito</option>
                                        <option value="Boleto">Boleto</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="bg-white border shadow rounded p-3 mt-3">
                            <h5 class="text-center fw-bold">Finalização</h5>
                            <div class="d-flex justify-content-center my-3">
                                <vue-recaptcha ref="recaptcha" :sitekey="'6LeCmDweAAAAAOFMfjwg-rU4OKOquLvTJ2lcd0LM'" @verify="verifyRecaptcha"></vue-recaptcha>
                            </div>
                            <p class="fst-italic text-center fs-aux">Ao confirmar "Não sou um robô", você de acordo com os <a class="fw-bold text-decoration-underline router-link text-dark hover" data-bs-toggle="modal" data-bs-target="#modalTerms">termos de uso</a> de nosso site</p>
                            <div class="d-flex justify-content-around align-items-center">
                                <h5 class="fw-bold">{{ priceTotalShow }}</h5>
                                <button @click="finish()" ref="btnFinish" class="btn btn-success" type="button">Finalizar Pedido</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <input class="form-control w-auto d-none" v-model="priceShow" v-money="money" type="text">
            <input class="form-control w-auto d-none" v-model="priceShipShow" v-money="money" type="text">
            <input class="form-control w-auto d-none" v-model="priceTotalShow" v-money="money" type="text">
            <input class="form-control w-auto d-none" v-model="pacPriceShow" v-money="money" type="text">
            <input class="form-control w-auto d-none" v-model="sedexPriceShow" v-money="money" type="text">
        </div>
        <Footer />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductCart from '@/components/ProductCart'
import Footer from '@/components/Footer'
import {VMoney} from 'v-money'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
    components: {
        ProductCart,
        Footer,
        VueRecaptcha 
    },

    data() {
        return {
            ship: 'pickup',
            priceShip: 0,
            success: '',

            priceShow: this.price,
            priceShipShow: 0,
            priceTotalShow: 0,
            pacPriceShow: 0,
            sedexPriceShow: 0,

            infos: {
                name: '',
                phone: '',
                email: '',
                cpf: '',
                code: '',
                street: '',
                number: '',
                complement: '',
                district: '',
                city: '',
                state: '',
                ship: '',

                pacPrice: '',
                pacDays: '',
                sedexPrice: '',
                sedexDays: '',

                payment: '',
                recaptcha: ''
            },

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    directives: {
        money: VMoney
    },

    computed: {
        ...mapGetters({
            products: 'getCartProducts',
            size: 'getCartSize',
            price: 'getCartPrice',
        })
    },

    methods: {
        async shipping() {
            this.$parent.loading = true
            this.$parent.hideAlert()
            await this.$http.post('cart/shipping', {
                code: this.infos.code,
            })
            .then((response) => {
                if(response.data.status == true) {
                    this.infos.street = response.data.street
                    this.infos.district = response.data.district
                    this.infos.city = response.data.city
                    this.infos.state = response.data.state
                    this.infos.pacPrice = response.data.pacPrice
                    this.infos.pacDays = response.data.pacDays
                    this.infos.sedexPrice = response.data.sedexPrice
                    this.infos.sedexDays = response.data.sedexDays

                    this.$refs.btnCalc.style.display = 'none'
                    this.$refs.btnCalc.disabled = true
                    this.$refs.inputCode.setAttribute('readonly', true)
                }
                else
                {
                    this.$parent.showAlert('error', 'Não foi possível encontrar o endereço')
                }
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível encontrar o endereço')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        async finish() {
            const products = JSON.parse(localStorage.getItem('_cart'))

            this.$refs.btnFinish.innerText = 'Verificando...'
            this.$refs.btnFinish.disabled = true
            this.$parent.hideAlert()

            await this.$http.post('cart/finish', {
                'products': JSON.stringify(products),
                'name': this.infos.name,
                'phone': this.infos.phone,
                'cpf': this.infos.cpf,
                'email': this.infos.email,
                'ship': this.ship,
                'code': this.infos.code,
                'number': this.infos.number,
                'complement': this.infos.complement,
                'typeShip': this.infos.ship,
                'payment': this.infos.payment,
                'recaptcha': this.infos.recaptcha
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    this.success = response.data.message
                    this.$store.commit('cartReset')
                    window.scrollTo(0, 0)
                }
                else
                {
                    this.$parent.showAlert('error', response.data.message)
                }
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível finalizar o pedido')
            })
            .finally(() => {
                this.$refs.btnFinish.innerHTML = 'Finalizar Pedido'
                this.$refs.btnFinish.disabled = false
                this.$refs.recaptcha.reset()
                this.infos.recaptcha = ''
            })
        },

        clearFieldsShipping() {
            this.infos.code = ''
            this.infos.street = ''
            this.infos.number = ''
            this.infos.complement = ''
            this.infos.district = ''
            this.infos.city = ''
            this.infos.state = ''
            this.infos.ship = ''

            this.$refs.btnCalc.style.display = 'block'
            this.$refs.btnCalc.disabled = false
            this.$refs.inputCode.removeAttribute('readonly')
        },

        verifyRecaptcha(response){
            this.infos.recaptcha = response
        }
    },

    watch: {
        'infos.pacPrice'() {
            this.pacPriceShow = this.infos.pacPrice.toFixed(2)
        },

        'infos.sedexPrice'() {
            this.sedexPriceShow = this.infos.sedexPrice.toFixed(2)
        },

        'infos.ship'() {
            if(this.infos.ship == 'Delivery') {
                this.priceShip = 15
            }
            else if(this.infos.ship == 'Pac') {
                this.priceShip = this.infos.pacPrice
            }
            else if(this.infos.ship == 'Sedex') {
                this.priceShip = this.infos.sedexPrice
            }
            else
            {
                this.priceShip = 0
            }
            this.priceShipShow = this.priceShip.toFixed(2)
            this.priceTotalShow = (this.price + this.priceShip).toFixed(2)
        },

        price() {
            this.priceShow = this.price.toFixed(2)
            this.priceTotalShow = (this.price + this.priceShip).toFixed(2)
        },

        ship() {
            if(this.ship == 'pickup') {
                this.clearFieldsShipping()
            }
        }
    },

    created() {
        this.priceShow = this.price.toFixed(2)
        this.priceTotalShow = (this.price + this.priceShip).toFixed(2)
        this.$parent.loading = true
    },

    mounted() {
        setTimeout(() => {
            this.$parent.loading = false
        }, 1500);
    }
}
</script>

<style>
.bg-finish{
    background: #fed002bb;
}

.fs-aux{
    font-size: 12px;
}
</style>