import Vue from 'vue'
import Vuex from 'vuex'
import http from '@/services/axios'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        cart: {
            products: [],
            size: 0,
            price: 0,
        }
    },

    mutations: {
        async cartLoad(state) {
            const products = JSON.parse(localStorage.getItem('_cart'))
        
            if(products != null) {
                state.cart.products = []
                await http.post('cart/get', {
                    'products': JSON.stringify(products)
                })
                .then((response) => {
                    state.cart.products = response.data
                    this.commit('cartReload')
                })
                .catch(() => {
                    console.log('Não foi possível carregar os produtos do carrinho')
                })
            }
        },

        cartReload(state) {
            var size = 0
            var price = 0

            state.cart.products.forEach(product => {
                size += product.amount
                price += product.price * product.amount
            })
            state.cart.size = size
            state.cart.price = price
        },

        cartAdd(state, product) {
            const pos = state.cart.products.findIndex(item => item.id == product.id)
        
            if(pos != -1) {
                state.cart.products[pos].amount += product.amount
            }
            else
            {
                const prod = {
                    'id': product.id,
                    'title': product.title,
                    'image': product.image,
                    'amount': product.amount,
                    'price': product.price
                }
                state.cart.products.push(prod)
            }
            this.commit('cartSave')
        },

        cartSave(state) {
            localStorage.setItem('_cart', JSON.stringify(state.cart.products))
            this.commit('cartReload')
        },

        cartReset(state) {
            state.cart.products = []
            this.commit('cartSave')
        },

        subProduct(state, id) {
            const pos = state.cart.products.findIndex(item => item.id == id)
            state.cart.products[pos].amount --
            this.commit('cartSave')
        },

        addProduct(state, id) {
            const pos = state.cart.products.findIndex(item => item.id == id)
            state.cart.products[pos].amount ++
            this.commit('cartSave')
        },

        delProduct(state, id) {
            state.cart.products = state.cart.products.filter(item => item.id != id);
            this.commit('cartSave')
        }
    },

    getters: {
        getCartProducts(state) {
            return state.cart.products
        },

        getCartSize(state) {
            return state.cart.size
        },

        getCartPrice(state) {
            return state.cart.price
        }
    }
})
