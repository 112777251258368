<template>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasCart">
        <div class="offcanvas-header bg-light d-flex align-items-center p-3">
            <h5>Meu Carrinho ({{ size }})</h5>
            <h5 class="text-success fw-bold">{{ priceShow }}</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body">
            <div v-if="products == ''" class="d-flex justify-content-center align-items-center text-center h-100">
                <span class="material-icons md-60 me-3">remove_shopping_cart</span>
                Seu carrinho esta vazio
            </div>

            <ProductCart v-for="(product, n) in products"
                :key="n"
                :id="product.id"
                :title="product.title"
                :price="product.price"
                :image="product.image"
                :amount="product.amount"
            />
            <button v-if="size > 0" @click="goFinish()" class="btn btn-success w-100">Finalizar Carrinho</button>
        </div>

        <input class="form-control w-auto d-none" v-model="priceShow" v-money="money" type="text">
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductCart from '@/components/ProductCart'
import {VMoney} from 'v-money'

export default {
    components: {
        ProductCart
    },

    data() {
        return {
            priceShow: this.price,

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    directives: {
        money: VMoney
    },

    computed: {
        ...mapGetters({
            products: 'getCartProducts',
            size: 'getCartSize',
            price: 'getCartPrice'

        })
    },

    methods: {
        goFinish() {
            window.location.href = '/finalizar'
        }
    },

    watch: {
        price() {
            this.priceShow = this.price.toFixed(2)
        }
    },

    created() {
        this.$store.commit('cartLoad')
    }
}
</script>

<style>
#offcanvasCart{
    background: rgba(235, 235, 235, 0.98);
}
</style>