<template>
    <div class="container py-2 my-2">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mt-2">
                <li class="breadcrumb-item">
                    <router-link class="router-link text-dark" to="/">Início</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="router-link text-dark" to="/painel">Painel</router-link>
                </li>
                <li class="breadcrumb-item">
                    Pedidos
                </li>
            </ol>
        </nav>

        <form @submit.prevent="searchOrder()">
            <div class="row d-flex justify-content-end m-0 mb-2">
                <div class="input-group w-auto p-0">
                    <input v-model="search" type="text" class="form-control" placeholder="Buscar por...">
                    <button type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li @click="searchTypeOrder('Todos')"><a class="dropdown-item hover">Todos</a></li>
                        <li @click="searchTypeOrder('Aguardando')"><a class="dropdown-item hover">Aguardando</a></li>
                        <li @click="searchTypeOrder('Atendendo')"><a class="dropdown-item hover">Atendendo</a></li>
                        <li @click="searchTypeOrder('Separado')"><a class="dropdown-item hover">Separado</a></li>
                        <li @click="searchTypeOrder('Enviando')"><a class="dropdown-item hover">Enviando</a></li>
                        <li @click="searchTypeOrder('Cancelado')"><a class="dropdown-item hover">Cancelado</a></li>
                        <li @click="searchTypeOrder('Concluído')"><a class="dropdown-item hover">Concluído</a></li>
                    </ul>
                    <button type="submit" class="btn btn-secondary rounded-end me-3">Buscar</button>
                </div>
            </div>
        </form>

        <div v-if="searchShow != ''" class="mt-3">Você esta busando por: <b>{{ searchShow }}</b></div>
        <div v-if="orders == ''" class="d-flex justify-content-center align-items-center border rounded p-5 mt-3">Nenhum pedido foi encontrado</div>

        <div class="row mt-0 g-2 mb-4">
            <OrderTable v-for="(order, n) in orders" 
                :key="n"
                :id="order.id"
                :name="order.name"
                :phone="order.phone"
                :cpf="order.cpf"
                :email="order.email"
                :products="order.products"
                :price="order.price"
                :code="order.code"
                :street="order.street"
                :number="order.number"
                :complement="order.complement"
                :district="order.district"
                :city="order.city"
                :state="order.state"
                :ship="order.ship"
                :shipPrice="order.shipPrice"
                :shipDays="order.shipDays"
                :payment="order.payment"
                :total="order.total"
                :status="order.status"
                :comment="order.comment"
                :created_at="order.created_at"
            />
        </div>

        <Pagination v-if="search != ''" :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="searchOrder"></Pagination>
        <Pagination v-else-if="searchType != ''" :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="typeOrder"></Pagination>
        <Pagination v-else :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="ordersLoad"></Pagination>
    </div>
</template>

<script>
import OrderTable from '@/components/OrderTable'
import Pagination from 'advanced-laravel-vue-paginate'
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'

export default {
    components: {
        OrderTable,
        Pagination,
    },

    data() {
        return {
            orders: [],
            search: '',
            searchType: '',
            searchShow: '',

            pagination: {},
        }
    },

    methods: {
        async ordersLoad(page) {
            if(typeof page === 'undefined') {
                page = 1;
            }

            this.orders = []
            this.$parent.loading = true

            await this.$http.get('panel/orders?page=' + page, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.pagination = response.data
                this.orders = response.data.data
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível carregar os pedidos')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        async searchOrder(page) {
            const formData = new FormData()
            formData.append('search', this.search)

            if(typeof page === 'undefined') {
                page = 1;
            }

            this.orders = []
            this.$parent.loading = true
            this.searchType = ''

            await this.$http.post('panel/orders/search?page=' + page, formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.pagination = response.data
                this.orders = response.data.data
                this.searchShow = this.search
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível fazer a busca')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        async typeOrder(page) {
            const formData = new FormData()
            formData.append('type', this.searchType)

            if(typeof page === 'undefined') {
                page = 1;
            }

            this.orders = []
            this.$parent.loading = true
            this.search = ''

            await this.$http.post('panel/orders/search/type?page=' + page, formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.pagination = response.data
                this.orders = response.data.data
                this.searchShow = this.searchType
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível fazer a busca')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        searchTypeOrder(type) {
            this.searchType = type
            this.typeOrder()
        },
    },

    created() {
        this.ordersLoad()
    }
}
</script>

<style>
</style>