<template>
    <div class="container py-2 my-2">
        <div class="row">
            <div class="col-6">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb mt-2">
                        <li class="breadcrumb-item">
                            <router-link class="router-link text-dark" to="/">Início</router-link>
                        </li>
                        <li class="breadcrumb-item">
                            Painel
                        </li>
                    </ol>
                </nav>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <div class="d-flex align-items-center">
                    <div class="flex-grow-1 text-truncate">
                        {{ user.name }}
                    </div>
                    <div class="flex-shrink-0 ms-3 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src="@/assets/user.png" class="hover" width="40" height="40">
                    </div>
                    <ul class="dropdown-menu">
                        <li ><a class="dropdown-item hover" data-bs-toggle="modal" data-bs-target="#modalPassword">Alterar Senha</a></li>
                        <li @click="logout()"><a class="dropdown-item hover text-danger">Sair</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-lg-4 mt-1 mb-3">
                <router-link class="router-link" to="/painel/produtos">
                    <div class="d-flex h-100 rounded list-group-item-primary border border-primary shadow p-3">
                        <div class="flex-shrink-0 zoom">
                            <img src="@/assets/products.png" width="100" height="100">
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5 class="fw-bold">Produtos</h5>
                            <p class="m-0">Cadastrados: {{ products.total }}</p>
                            <p class="m-0">Promoções: {{ products.sale }}</p>
                            <p class="m-0">Ativados: {{ products.active }}</p>
                            <p class="m-0">Desativados: {{ products.disabled }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-lg-4 mt-1 mb-3">
                <router-link class="router-link" to="/painel/pedidos">
                    <div class="d-flex h-100 rounded list-group-item-warning border border-warning shadow p-3">
                        <div class="flex-shrink-0 zoom">
                            <img src="@/assets/orders.png" width="100" height="100">
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5 class="fw-bold">Pedidos</h5>
                            <p class="m-0">Aguardando: {{ orders.waiting }}</p>
                            <p class="m-0">Atendendo: {{ orders.doing }}</p>
                            <p class="m-0">Separado: {{ orders.separate }}</p>
                            <p class="m-0">Enviando: {{ orders.sending }}</p>
                            <p class="m-0">Cancelado: {{ orders.canceled }}</p>
                            <p class="m-0">Concluído: {{ orders.done }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="col-lg-4 mt-1 mb-3">
                <router-link class="router-link" to="/painel/historico">
                    <div class="d-flex h-100 rounded list-group-item-dark border border-dark shadow p-3">
                        <div class="flex-shrink-0 zoom">
                            <img src="@/assets/historic.png" width="100" height="100">
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5 class="fw-bold">Histórico</h5>
                            <p>Acompanhe todas as criações, edições e exclusão dos produtos e pedidos</p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="modal fade" id="modalPassword" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="changePassword()" autocomplete="off">
                        <div class="modal-header">
                            <h5 class="modal-title">Alterar Senha</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Senha Atual</label>
                                    <input v-model="user.password" type="password" class="form-control">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label m-0 fw-bold">Senha Nova</label>
                                    <input v-model="user.newPassword" type="password" class="form-control">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label m-0 fw-bold">Confirmar Senha</label>
                                    <input v-model="user.confirmPassword" type="password" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button ref="btnSave" type="submit" class="btn btn-success">Salvar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    
    },

    data() {
        return {
            user: {
                name: '',
                password: '',
                newPassword: '',
                confirmPassword: ''
            },

            products: {
                total: 0,
                sale: 0,
                active: 0,
                disabled: 0
            },

            orders: {
                waiting: 0,
                doing: 0,
                separate: 0,
                seding: 0,
                canceled: 0,
                done: 0
            }
        }
    },

    methods: {
        async getUser() {
            this.$parent.loading = true

            await this.$http.get('auth/me', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.user.name = response.data.username
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível carregar seu usuário')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        async dataLoad() {
            this.$parent.loading = true
            await this.$http.get('panel', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.products.total = response.data.products.total
                this.products.sale = response.data.products.sale
                this.products.active = response.data.products.active
                this.products.disabled = response.data.products.disabled

                this.orders.waiting = response.data.orders.waiting
                this.orders.doing = response.data.orders.doing
                this.orders.separate = response.data.orders.separate
                this.orders.sending = response.data.orders.sending
                this.orders.canceled = response.data.orders.canceled
                this.orders.done = response.data.orders.done
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível carregar os dados do painel')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        },

        async changePassword() {
            const formData = new FormData()
            formData.append('password', this.user.password)
            formData.append('newPassword', this.user.newPassword)
            formData.append('confirmPassword', this.user.confirmPassword)

            this.$refs.btnSave.innerText = 'Verificando...'
            this.$refs.btnSave.disabled = true

            await this.$http.post('auth/change', formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    this.$parent.showAlert('success', response.data.message)
                    this.clearFieldsChange()
                }
                else
                {
                    this.$parent.showAlert('error', response.data.message)
                }
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível realizar a alteração de senha')
            })
            .finally(() => {
                this.$refs.btnSave.innerText = 'Salvar'
                this.$refs.btnSave.disabled = false
            })
        },

        async logout() {
            await this.$http.get('auth/logout', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then(() => {
                this.$router.push('/login')
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível deslogar')
            })
        },

        clearFieldsChange() {
            this.user.password = ''
            this.user.newPassword = ''
            this.user.confirmPassword = ''
        }
    },

    created() {
        this.getUser()
        this.dataLoad()
    }
}
</script>

<style>
.zoom {
    overflow: hidden;
}

.zoom img:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition-duration: 0.5s;
}
</style>