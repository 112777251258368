import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from '@/services/middleware'

import Login from '@/views/Login'
import Inicio from '@/views/Inicio'
import Promocoes from '@/views/Promocoes'
import Panel from '@/views/Panel'
import PanelProducts from '@/views/PanelProducts'
import PanelOrders from '@/views/PanelOrders'
import PanelLogs from '@/views/PanelLogs'
import Finish from '@/views/Finish'
import Search from '@/views/Search'

import Doces from '@/views/products/Doces'
import Chocolates from '@/views/products/Chocolates'
import Biscoitos from '@/views/products/Biscoitos'
import Salgadinhos from '@/views/products/Salgadinhos'
import Descartaveis from '@/views/products/Descartaveis'
import Utensilios from '@/views/products/Utensilios'
import Decoracoes from '@/views/products/Decoracoes'
import Baloes from '@/views/products/Baloes'
import Confeitaria from '@/views/products/Confeitaria'
import Embalagens from '@/views/products/Embalagens'
import Bebidas from '@/views/products/Bebidas'
import Brinquedos from '@/views/products/Brinquedos'
import Vestimentas from '@/views/products/Vestimentas'
import Cosmeticos from '@/views/products/Cosmeticos'
import Diversos from '@/views/products/Diversos'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: Login,
        name: 'Login',
        beforeEnter: middleware.guest
    },
    {
        path: '/',
        component: Inicio,
        name: 'Inicio'
    },
    {
        path: '/promocoes',
        component: Promocoes,
        name: 'Promoções'
    },
    {
        path: '/painel',
        component: Panel,
        name: 'Painel',
        beforeEnter: middleware.auth
    },
    {
        path: '/painel/produtos',
        component: PanelProducts,
        name: 'Produtos',
        beforeEnter: middleware.auth
    },
    {
        path: '/painel/pedidos',
        component: PanelOrders,
        name: 'Pedidos',
        beforeEnter: middleware.auth
    },
    {
        path: '/painel/historico',
        component: PanelLogs,
        name: 'Histórico',
        beforeEnter: middleware.auth
    },
    {
        path: '/finalizar',
        component: Finish,
        name: 'Finalizar Pedido',
    },
    {
        path: '/buscar',
        component: Search,
        name: 'Buscar',
    },
    {
        path: '/doces',
        component: Doces,
        name: 'Doces',
    },
    {
        path: '/chocolates',
        component: Chocolates,
        name: 'Chocolates',
    },
    {
        path: '/biscoitos',
        component: Biscoitos,
        name: 'Biscoitos',
    },
    {
        path: '/salgadinhos',
        component: Salgadinhos,
        name: 'Salgadinhos',
    },
    {
        path: '/descartaveis',
        component: Descartaveis,
        name: 'Descartáveis',
    },
    {
        path: '/utensilios',
        component: Utensilios,
        name: 'Utensílios',
    },
    {
        path: '/decoracoes',
        component: Decoracoes,
        name: 'Decorações',
    },
    {
        path: '/baloes',
        component: Baloes,
        name: 'Balões',
    },
    {
        path: '/confeitaria',
        component: Confeitaria,
        name: 'Confeitaria',
    },
    {
        path: '/embalagens',
        component: Embalagens,
        name: 'Embalagens',
    },
    {
        path: '/bebidas',
        component: Bebidas,
        name: 'Bebidas',
    },
    {
        path: '/brinquedos',
        component: Brinquedos,
        name: 'Brinquedos',
    },
    {
        path: '/vestimentas',
        component: Vestimentas,
        name: 'Vestimentas',
    },
    {
        path: '/cosmeticos',
        component: Cosmeticos,
        name: 'Cosmeticos',
    },
    {
        path: '/diversos',
        component: Diversos,
        name: 'Diversos',
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
