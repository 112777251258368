<template>
    <div class="d-flex border shadow rounded bg-white p-1">
        <div class="flex-shrink-0 border-end d-flex align-items-center p-2">
            <img :src="this.$http.defaults.baseURL2 + '/storage/' + product.image" width="60" height="60">
        </div>
        <div class="flex-grow-1 p-2">
            <h6 class="title text-nowarp"><b>[#{{ id }}]</b> {{ product.title }}</h6>
            <div class="row m-0 mb-1">
                <div class="col-12 col-md-6 p-0">
                    <div v-if="product.isSale == true"><b>Preço:</b> <i class="text-decoration-line-through me-1">{{ product.price }}</i> por <span class="badge bg-warning text-dark ms-1 px-3">{{ product.priceSale }}</span></div>
                    <div v-else><b>Preço:</b> {{ product.price }}</div>
                    <div><b>Código:</b> {{ product.cod }}</div>
                </div>
                <div class="col-12 col-md-6 p-0">
                    <div><b>Categoria:</b> {{ product.type }}</div>
                    <div v-if="product.status == true"><b>Situação:</b> Ativado</div>
                    <div v-else><b>Situação:</b> <span class="badge bg-dark ms-1">Desativado</span></div>
                </div>
            </div>
            <button type="button" class="btn btn-primary btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#modalEdit_' + id">Editar</button>
            <button type="button" class="btn btn-outline-danger btn-sm" data-bs-toggle="modal" :data-bs-target="'#modalDelete_' + id">Excluir</button>
        </div>

        <input class="form-control w-auto d-none" v-model="product.price" v-money="money" type="text">
        <input class="form-control w-auto d-none" v-model="product.priceSale" v-money="money" type="text">

        <div class="modal fade" :id="'modalEdit_' + id" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <form @submit.prevent="editProduct()">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Editar</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="d-flex">
                                        <div class="flex-shrink-0 d-flex align-items-center">
                                            <img :src="this.$http.defaults.baseURL2 + '/storage/' + product.image" width="100" height="100">
                                        </div>
                                        <div class="flex-grow-1 ms-1">
                                            <label class="form-label m-0 fw-bold">Produto</label>
                                            <input v-model="productEdit.title" type="text" class="form-control text-uppercase">
                                            <input @change="onImageEdit" ref="imageEdit" class="form-control mt-2" type="file">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label m-0 fw-bold">Preço</label>
                                    <input v-model="productEdit.price" type="text" class="form-control" inputmode="numeric" v-money="money">
                                </div>
                                <div class="col-12 col-md-6 mb-3">
                                    <label class="form-label m-0 fw-bold">Código</label>
                                    <input v-model="productEdit.cod" type="text" class="form-control" inputmode="numeric" @keydown.enter.prevent>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Categoria</label>
                                    <select v-model="productEdit.type" class="form-select">
                                        <option selected disabled>Selecione</option>
                                        <option v-for="(type, n) in listTypes" :key="n" :value="type">{{ type }}</option>
                                    </select>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Situação</label>
                                    <select v-model="productEdit.status" class="form-select">
                                        <option value="1">Ativado</option>
                                        <option value="0">Desativado</option>
                                    </select>
                                </div>
                                <div class="col-6 mb-3 d-flex align-items-center">
                                    <div class="form-check">
                                        <input v-model="productEdit.isSale" class="form-check-input" type="checkbox">
                                        <label class="form-check-label">Em promoção</label>
                                    </div>
                                </div>
                                <div v-if="productEdit.isSale == true" class="col-6 mb-3">
                                    <input v-model="productEdit.priceSale" type="text" class="form-control" inputmode="numeric" v-money="money">
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button ref="btnCloseEdit" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button ref="btnEdit" type="submit" class="btn btn-primary">Editar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="modal fade" :id="'modalDelete_' + id" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <form @submit.prevent="deleteProduct()">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Excluir</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Você tem certeza que deseja excluir o produto: <b>{{ product.title }}</b> ?
                        </div>
                        <div class="modal-footer">
                            <button ref="btnCloseDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button ref="btnDelete" type="submit" class="btn btn-danger">Excluir</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {VMoney} from 'v-money'

export default {
    props: ['id', 'title', 'price', 'isSale', 'priceSale', 'cod', 'type', 'status', 'image'],

    data() {
        return {
            listTypes: ['Doces', 'Chocolates', 'Biscoitos', 'Salgadinhos', 'Descartáveis', 'Utensílios', 'Decorações', 'Balões', 'Confeitaria', 'Embalagens', 'Bebidas', 'Brinquedos', 'Vestimentas', 'Cosméticos', 'Diversos'],

            product: {
                title: this.title,
                price: this.price,
                isSale: this.isSale,
                priceSale: this.priceSale,
                cod: this.cod,
                type: this.type,
                status: this.status,
                image: this.image
            },

            productEdit: {
                title: this.title,
                image: this.image,
                price: this.price,
                cod: this.cod,
                type: this.type,
                status: this.status,
                isSale: this.isSale,
                priceSale: this.priceSale,
            },

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    directives: {
        money: VMoney
    },

    methods: {
        onImageEdit(event) {
            this.productEdit.image = event.target.files[0]
        },

        async editProduct() {
            const formData = new FormData()
            formData.append('title', this.productEdit.title)
            formData.append('image', this.productEdit.image)
            formData.append('price', this.productEdit.price)
            formData.append('cod', this.productEdit.cod)
            formData.append('type', this.productEdit.type)
            formData.append('status', this.productEdit.status)
            formData.append('isSale', this.productEdit.isSale)
            formData.append('priceSale', this.productEdit.priceSale)

            this.$refs.btnEdit.innerText = 'Verificando...'
            this.$refs.btnEdit.disabled = true
            this.$parent.$parent.hideAlert()

            await this.$http.post('panel/products/' + this.id + '/edit', formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    this.$parent.$parent.showAlert('success', response.data.message)
                    this.product.title = response.data.product.title
                    this.product.image = response.data.product.image
                    this.product.price = response.data.product.price
                    this.product.cod = response.data.product.cod
                    this.product.type = response.data.product.type
                    this.product.status = response.data.product.status
                    this.product.isSale = response.data.product.isSale
                    this.product.priceSale = response.data.product.priceSale
                    this.productEdit.image = ''
                    this.$refs.imageEdit.value = null
                    this.$refs.btnCloseEdit.click()
                }
                else
                {
                    this.$parent.$parent.showAlert('error', response.data.message)
                }
            })
            .catch(() => {
                this.$parent.$parent.showAlert('error', 'Não foi possível editar o produto')
            })
            .finally(() => {
                this.$refs.btnEdit.innerText = 'Editar'
                this.$refs.btnEdit.disabled = false
            })
        },

        async deleteProduct() {
            this.$refs.btnDelete.innerText = 'Verificando...'
            this.$refs.btnDelete.disabled = true
            this.$parent.$parent.hideAlert()

            await this.$http.post('panel/products/' + this.id + '/delete', '', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    this.$parent.$parent.showAlert('success', response.data.message)
                    this.$refs.btnCloseDelete.click()

                    setTimeout(() => {
                        this.$parent.productsLoad()
                    }, 1000)
                }
                else
                {
                    this.$parent.$parent.showAlert('error', response.data.message)
                }
            
            })
            .catch(() => {
                this.$parent.$parent.showAlert('error', 'Não foi possível excluir o produto')
            })
            .finally(() => {
                this.$refs.btnDelete.innerText = 'Excluir'
                this.$refs.btnDelete.disabled = false
            })
        }
    },

    watch: {
        'productEdit.isSale'() {
            if(this.productEdit.isSale === true) {
                this.productEdit.isSale = 1
            }
            else if(this.productEdit.isSale === false)
            {
                this.productEdit.isSale = 0
            }
        }
    }
}
</script>

<style>
.title {
    height: 36px;
    overflow: hidden;
}
</style>