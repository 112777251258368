<template>
    <div v-if="show == true" id="loading" class="fixed-top h-100 d-flex justify-content-center align-items-center">
        <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['show']
}
</script>

<style>
#loading {
    background: rgba(255, 255, 255, 0.9);
    z-index: 99999;
}
</style>