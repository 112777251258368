<template>
    <div class="d-flex border my-3 border rounded bg-white shadow p-2">
        <div class="flex-shrink-0 border-end d-flex align-items-center p-1">
            <img :src="this.$http.defaults.baseURL2 + '/storage/' + image" width="50" height="50">
        </div>
        <div class="flex-grow-1 ms-2 pe-1">
            <p class="title-product fw-light text-nowarp">{{ title }}</p>
            <div class="row m-0">
                <div class="col-4 text-center">
                    <div class="fst-italic fs-aux">Quanti.</div>
                    <div class="mt-2 fw-bold">{{ amount }}</div>
                </div>
                <div class="col-4 align-items-center text-center">
                    <div class="fst-italic fs-aux">Preço</div>
                    <div class="fw-bold fs-aux mt-2">{{ priceShow }}</div>
                </div>
                <div class="col-4 align-items-center text-center">
                    <div class="fst-italic fs-aux">Código</div>
                    <input v-model="cod" @click="copy()" ref="cod" type="text" class="form-control form-control-sm text-center hover" readonly>
                </div>
            </div>
        </div>

         <input class="form-control w-auto d-none" v-model="priceShow" v-money="money" type="text">
    </div>
</template>

<script>
import {VMoney} from 'v-money'

export default {
    props: ['id', 'title', 'price', 'cod', 'type', 'status', 'image', 'amount'],

    data() {
        return {
            priceShow: this.price,

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    methods: {
        copy() {
            this.$refs.cod.select()
            document.execCommand('copy')
            this.$parent.$parent.$parent.showAlert('success', 'Código copiado')
        }
    },

    directives: {
        money: VMoney
    }
}
</script>

<style>
.title-product {
    height: 40px;
    font-size: 14px;
    overflow: hidden;
}
</style>