<template>
    <div v-if="show == true" id="alert" class="fixed-bottom mx-auto">
        <div v-if="type == 'error'" class="alert alert-danger alert-dismissible border border-2 border-danger rounded w-auto shadow mt-1" role="alert">
            <b>Erro:</b> {{ message }}
            <button @click="hide()" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div v-else-if="type == 'success'" class="alert alert-success alert-dismissible border border-2 border-success rounded w-auto shadow mt-1" role="alert">
            <b>Sucesso:</b> {{ message }}
            <button @click="hide()" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['show', 'type', 'message'],

    methods: {
        hide() {
            this.$parent.alert.show = false
            this.$parent.alert.type = ''
            this.$parent.alert.message = ''
        }
    }
}
</script>

<style>
#alert{
    max-width: 320px;
    z-index: 9999;
}
</style>