<template>
    <div class="col">
        <div class="card h-100 border shadow">
            <div class="zoom">
                <span v-if="isSale == true" class="badge rounded-pill list-group-item-danger ms-2 mt-2 position-absolute border border-2 border-danger z-index">PROMOÇÃO</span>
                <img :src="this.$http.defaults.baseURL2 + '/storage/' + image" class="card-img-top" height="190">
            </div>
            <div class="card-body border-top">
                <p class="card-title text-center">{{ title }}</p>
            </div>
            <div class="card-footer text-center border-0 bg-transparent">
                <p v-if="isSale == true" class="card-text fw-bold">
                    <span class="fw-normal text-decoration-line-through me-1">{{ priceShow }}</span>
                    <span class="text-danger">{{ priceSaleShow }}</span>
                </p>
                <p v-else class="card-text fw-bold">{{ priceShow }}</p>
                <div class="row align-items-center">
                    <div class="col-12 col-md-6 mt-1">
                        <div class="input-group">
                            <button v-if="product.amount == 0" type="button" class="btn-sm p-0 d-flex align-items-center no-outline bg-transparent text-secondary rounded border-0">
                                <span class="material-icons md-20 hover">remove_circle</span>
                            </button>
                            <button v-else @click="sub()" type="button" class="btn-sm p-0 d-flex align-items-center no-outline bg-transparent text-danger rounded border-0">
                                <span class="material-icons md-20 hover">remove_circle</span>
                            </button>
                            <input v-model="product.amount" type="text" class="form-control form-control-sm text-center rounded no-outline mx-md-1 mx-3" placeholder="0" readonly>
                            <button @click="add()" type="button" class="btn-sm p-0 d-flex align-items-center no-outline bg-transparent text-success rounded border-0">
                                <span class="material-icons md-20 hover">add_circle</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mt-1">
                        <button @click="addCart()" ref="btnAddCart" class="btn btn-success btn-sm rounded w-100 text-truncate" type="button">Adicionar</button>
                    </div>
                </div>
            </div>
        </div>

        <input class="form-control w-auto d-none" v-model="priceShow" v-money="money" type="text">
        <input class="form-control w-auto d-none" v-model="priceSaleShow" v-money="money" type="text">
    </div>
</template>

<script>
import {VMoney} from 'v-money'

export default {
    props: ['id', 'title', 'price', 'isSale', 'priceSale', 'image'],

    data() {
        return {
            priceShow: this.price,
            priceSaleShow: this.priceSale,

            product: {
                id: this.id,
                title: this.title,
                image: this.image,
                amount: 0,
                price: 0,
            },

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    directives: {
        money: VMoney
    },

    methods: {
        sub() {
            if(this.product.amount > 0) {
                this.product.amount --
            }
        },

        add() {
            this.product.amount ++
        },

        addCart() {
            this.$store.commit('cartAdd', this.product)
            this.product.amount = 0
        }
    },

    watch: {
        'product.amount'() {
            this.product.amount == 0 ? this.$refs.btnAddCart.disabled = true : this.$refs.btnAddCart.disabled = false
        }
    },

    created() {
        this.isSale == true ? this.product.price = this.priceSale : this.product.price = this.price
    },

    mounted() {
        this.$refs.btnAddCart.disabled = true
    }
}
</script>

<style>
.card-title{
    font-size: 14px;
}

.zoom {
    overflow: hidden;
}

.zoom img:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition-duration: 0.5s;
}
</style>