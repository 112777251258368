<template>
    <div class="container-fluid mt-5 py-5 bg-dark">
        <div class="container">
            <div class="row g-4">
                <div class="col-12 col-lg-3 text-white">
                    © Todos direitos reservados 2022<br />
                    Jujuba Fest<br />
                    CNPJ: 22.484.655/0001-15<br />
                    Endereço: Rua Quirino de Mello, 461 - Aparecidinha<br />
                    Sorocaba/SP - CEP: 18087-200<br />
                    <a class="text-decoration-underline router-link text-white hover" data-bs-toggle="modal" data-bs-target="#modalTerms">Termos de uso</a>
                </div>
                <div class="col-12 col-lg-3 text-white">
                    <h6 class="fw-bold mb-1">Horário de Funcionamento</h6>
                    Seg a Sex: 08h00 ás 18h00<br />
                    Sábados: 08h00 ás 17h00<br />
                    Domingos e Feriados: Fechado
                </div>
                <div class="col-12 col-lg-3 text-white">
                    <h6 class="fw-bold mb-1">Contatos</h6>
                    (15) 98157-2201<br />
                    (15) 3327-9932<br />
                    contato@jujubafest.com.br
                    <div class="d-flex mt-2">
                        <a href="https://wa.me/5515981572201?text=Olá,%20Acessei%20o%20site%20da%20Jujuba%20Fest" target="_blank"><img src="@/assets/whatsapp.png" width="25" height="25" /></a>
                        <a href="https://www.facebook.com/jujuba.fest21/" target="_blank"><img src="@/assets/facebook.png" class="mx-3" width="25" height="25" /></a>
                        <a href="https://www.instagram.com/jujuba.fest/" target="_blank"><img src="@/assets/instagram.png" width="25" height="25" /></a>
                    </div>
                </div>
                <div class="col-12 col-lg-3 d-flex justify-content-end">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14641.757286381267!2d-47.37409356529746!3d-23.44461261476332!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf5e08dd68cc35%3A0x84c65803c931fbc5!2sR.%20Quirino%20de%20Mello%2C%20461%20-%20Aparecidinha%2C%20Sorocaba%20-%20SP%2C%2018087-200!5e0!3m2!1spt-BR!2sbr!4v1727641945832!5m2!1spt-BR!2sbr" width="350" height="150" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalTerms" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Termos de Uso</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <b>Imagens:</b> São apenas ilustrativas. Se houver alguma dúvida sobre o produto, você pode estar entrando em contato conosco<br /><br />
                        <b>Preços:</b> Após enviar o pedido, os preços dos produtos e frete estão sujeitos a alterações. O valor é definido após nossos colaboradores passarem a mercadoria no caixa. Em caso de qualquer dúvida entre em contato conosco.<br /><br />
                        <b>Pagamento:</b> Nosso site não possui uma forma de pagamento online, portanto o pagamento é feito posteriormente através do nosso contato de WhatsApp. Antes de efetuar qualquer pagamento, CONFIRME os dados do favorecido, confirmando que somos nós.<br /><br />
                        <b>Dados Pessoais:</b> Nosso sistema armazena nome, telefone/celular, CPF, email e endereço dos clientes que geram pedidos. Porém, somente nossos colaboradores tem acesso a esse conteúdo.
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
