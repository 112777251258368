<template>
    <tr>
        <td v-if="type == 'create'"><span class="badge bg-success">Criado</span></td>
        <td v-else-if="type == 'update'"><span class="badge bg-primary">Editado</span></td>
        <td v-else-if="type == 'delete'"><span class="badge bg-danger">Deletado</span></td>
        <td><b>{{ dateShow }}</b> {{ text }}</td>
    </tr>
</template>

<script>
export default {
    props: ['type', 'text', 'created_at'],

    data() {
        return {
            dateShow: ''
        }
    },

    methods: {
        formatDate() {
            const date = new Date(this.created_at)
            const day = new Intl.DateTimeFormat('pt-BR').format(date)
            const hour = new Intl.DateTimeFormat('pt-BR', {hour: 'numeric', minute: 'numeric', timeZone: 'America/Sao_paulo'}).format(date)
            this.dateShow = '[' + day + ' - ' + hour + ']'
        }
    },

    created() {
        this.formatDate()
    }
}
</script>

<style>
</style>