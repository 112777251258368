<template>
    <div id="app">
        <Top />
        <div id="contentApp">
            <router-view />
        </div>
        <Cart />
        <Loading :show="loading" />
        <Alert :show="alert.show" :type="alert.type" :message="alert.message" />

        <!-- <div v-if="myIP != true" class="container-fluid h-100 fixed-top bg-white d-flex justify-content-center align-items-center">
            <img src="@/assets/logo.png" width="140" height="70" class="hover" />
            <h4 class="fw-bold">Site em construção</h4>
        </div> -->
    </div>
</template>

<script>
import Top from '@/components/Top'
import Cart from '@/components/Cart'
import Loading from '@/components/Loading'
import Alert from '@/components/Alert'

export default {
    components: {
        Top,
        Cart,
        Loading,
        Alert
    },

    data() {
        return {
            loading: false,

            alert: {
                show: false,
                type: '',
                message: '',
            },

            timer: '',

            myIP: '',
        }
    },

    methods: {
        showAlert(type, message) {
            clearTimeout(this.timer)
            this.timer = setTimeout(this.showAlert, 4000)
            this.alert.show = true
            this.alert.type = type
            this.alert.message = message
        },

        hideAlert() {
            this.alert.show = false
            this.alert.type = ''
            this.alert.message = ''
        },

        // async ip() {
        //     const response = await this.$http.get('https://api.ipify.org?format=json')

        //     if(response.data.ip == '189.32.246.170' || response.data.ip == '187.180.190.93' || response.data.ip == '189.32.228.34' || response.data.ip == '187.180.189.30') {
        //         this.myIP = true
        //     }
        // }
    },

    created() {
        // this.ip()
    }
}

</script>

<style>
body{
    font-family: 'Source Sans Pro', sans-serif;
    background: rgb(240, 240, 240);
}

#developping {
    /* background: rgba(255, 255, 255, 0.9); */
    z-index: 99999;
}

#contentApp{
    margin-top: 190px;
}

.router-link{
    text-decoration: none;
}

.hover{
    cursor: pointer;
}

.no-outline, .no-outline:focus{
    outline: none;
    box-shadow: none;
    border: none;
}

.font-arial{
    font-family: Arial;
}

.z-index{
    z-index: 3;
}

.material-icons.md-15 { font-size: 15px; }
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-20 { font-size: 20px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }
.material-icons.md-60 { font-size: 60px; }

::-webkit-scrollbar {
    width: 7px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: #423c3f;
    border-radius: 3px;
}

* {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
</style>
