<template>
    <div class="container-fluid p-0">
        <div id="carouselWallpaper" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselWallpaper" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselWallpaper" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselWallpaper" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="@/assets/wallpaper.jpeg" class="d-block w-100 img-fluid">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/wallpaper2.jpg" class="d-block w-100 img-fluid">
                </div>
                <div class="carousel-item">
                    <img src="@/assets/wallpaper3.jpg" class="d-block w-100 img-fluid">
                </div>
            </div>

            <button class="carousel-control-prev" type="button" data-bs-target="#carouselWallpaper" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselWallpaper" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

        <div class="container mt-5">
            <h4 class="fw-bold text-center text-danger mb-3">PROMOÇÕES</h4>
            <div class="row row-cols-2 row-cols-md-4 row-cols-lg-5 g-4 mb-4 d-flex justify-content-center">
                <Product v-for="(product, n) in productsSale"
                    :key="n"
                    :id="product.id"
                    :title="product.title"
                    :price="product.price"
                    :isSale="product.isSale"
                    :priceSale="product.priceSale"
                    :image="product.image"
                />
            </div>
            <div class="d-flex justify-content-center">
                <router-link class="router-link" to="/promocoes">
                    <button type="button" class="btn btn-danger">Ver todas as promoções</button>
                </router-link>
            </div>
        </div>

        <div class="container-fluid bg-warning mt-5 py-5 shadow">
            <h4 class="fw-bold text-center mb-3">DECORAÇÕES</h4>
            <div class="row g-4 d-flex justify-content-center">
                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                    <img src="@/assets/arranjo.jpg" width="250" height="250" class="d-block rounded-circle">
                </div>
                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                    <img src="@/assets/arranjo2.jpg" width="250" height="250" class="d-block rounded-circle">
                </div>
                <div class="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                    <img src="@/assets/arranjo3.jpeg" width="250" height="250" class="d-block rounded-circle">
                </div>
            </div>
        </div>

        <div class="container mt-5 py-5">
            <div class="d-flex">
                <div class="flex-shrink-0 d-none d-md-block shadow me-2">
                    <img src="@/assets/front.jpg" width="300" height="300" class="rounded border border-2 border-info">
                </div>
                <div class="flex-grow-1 ms-3">
                    <h3 class="fw-bold">Jujuba Fest</h3>
                    Nossa loja foi inaugurada no ano de 2021 e está localizada no município de Sorocaba/SP.<br />
                    Atuamos no mercado de varejo, focados no ramo de festas! Tudo o que você procura para deixar a sua festa linda e cheia de alegria você encontra na JUJUBA FEST!<br /><br />

                    Nossa equipe está preparada para recebê-los e auxilia-los no que for necessário, afinal o comprometimento e o respeito com o próximo é o nosso lema!
                    Em pouco tempo de mercado já conseguimos entender as necessidades do nosso público, sempre buscando oferecer os melhores produtos e preços da região!<br /><br />

                    A JUJUBA FEST está sempre almejando o crescimento responsável e seguro, cumprindo o dever da melhor entrega, seja em produtos, como principalmente no atendimento!
                    Venha nos fazer uma visita!
                </div>
            </div>
        </div>

        <div class="alert alert-primary alert-dismissible fade show fixed-bottom mx-2 shadow-lg" role="alert">
            <strong>Atenção!</strong> Ainda estamos cadastrando nossos produtos. Se você não encontrar os produtos que deseja, entre em contato conosco para tirar sua dúvida!
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>

        <Footer />
    </div>
</template>

<script>
import Product from '@/components/Product'
import Footer from '@/components/Footer'

export default {
    components: {
        Product,
        Footer
    },

    data() {
        return {
            productsSale: [],
        }
    },

    methods: {
        async productsSaleLoad() {
            this.productsSale = []
            this.$parent.loading = true

            await this.$http.get('products/isSaleHome').then((response) => {
                this.productsSale = response.data
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível carregar os produtos em promoção')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        }
    },

    created() {
        this.productsSaleLoad()
    }
}
</script>

<style>
@media (max-width: 575.98px) {
    #carouselWallpaper{
        height: 120px;
    }

    .carousel-item img{
        height: 120px;
    }
}
</style>