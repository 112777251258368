<template>
    <div class="d-flex border shadow rounded bg-white p-1">
        <div class="flex-shrink-0 border-end d-flex align-items-center">
            <span v-if="order.status == 'Aguardando'" class="material-icons md-48 text-warning me-1">timer</span>
            <span v-else-if="order.status == 'Atendendo'" class="material-icons md-48 text-secondary me-1">support_agent</span>
            <span v-else-if="order.status == 'Separado'" class="material-icons md-48 text-primary me-1">shopping_bag</span>
            <span v-else-if="order.status == 'Enviando'" class="material-icons md-48 text-info me-1">local_shipping</span>
            <span v-else-if="order.status == 'Concluído'" class="material-icons md-48 text-success me-1">task</span>
            <span v-else-if="order.status == 'Cancelado'" class="material-icons md-48 text-danger me-1">cancel</span>
        </div>
        <div class="flex-grow-1 py-3">
            <div class="row m-0">
                <div class="col-md-6">
                    <b>Pedido:</b> #{{ id }} - {{ order.status }}<br />
                    <b>Data:</b> {{ dateShow }}<br />
                    <b>Nome:</b> {{ name }}<br />
                    <b>CPF:</b> {{ cpf }}<br />
                    <b>Telefone:</b> {{ phone }}<br />
                    <div v-if="email == ''"><b>Email:</b> Nenhum</div>
                    <div v-else><b>Email:</b> {{ email }}</div>
                </div>
                <div class="col-md-6 mt-3">
                    <b>Produtos:</b> [{{ amount }}] <b class="text-primary">{{ priceShow }}</b>

                    <div v-if="ship == null">
                        <b>Frete:</b> Retirar na Loja
                    </div>
                    <div v-else>
                        <b>Frete:</b> [{{ ship }}, {{ shipDays }} dias] <b class="text-secondary">{{ shipPriceShow }}</b><br />
                        <b>Pagamento:</b> {{ payment }}<br />
                        <b>Endereço:</b> {{ street }}, nº {{ number }}, {{ district }} - CEP: {{ code }} - {{ city }}/{{ state }} <i v-if="complement != ''">[{{ complement }}]</i>
                    </div>
                    <b>Total:</b><b class="text-success"> {{ totalPriceShow }}</b>
                </div>
            </div>
            <button type="button" class="btn btn-dark btn-sm ms-2 mt-2" data-bs-toggle="modal" :data-bs-target="'#modalInfo_' + id">Ver</button>
            <button type="button" class="btn btn-primary btn-sm ms-2 mt-2" data-bs-toggle="modal" :data-bs-target="'#modalEdit_' + id">Editar</button>
            <button v-if="order.status == 'Cancelado'" type="button" class="btn btn-outline-danger btn-sm ms-2 mt-2" data-bs-toggle="modal" :data-bs-target="'#modalDelete_' + id">Excluir</button>
        </div>

        <input class="form-control w-auto d-none" v-model="priceShow" v-money="money" type="text">
        <input class="form-control w-auto d-none" v-model="shipPriceShow" v-money="money" type="text">
        <input class="form-control w-auto d-none" v-model="totalPriceShow" v-money="money" type="text">

        <div class="modal fade" :id="'modalInfo_' + id" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content bg-light">
                    <div class="modal-header">
                        <h5 class="modal-title">Pedido #{{ id }} - {{ order.status }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div v-if="order.comment != ''" class="mb-2">
                            <b class="me-1">Comentário:</b> {{ order.comment }}
                        </div>
                        <ProductOrder v-for="(product, n) in productsOrder"
                            :key="n"
                            :id="product.id"
                            :title="product.title"
                            :price="product.price"
                            :cod="product.cod"
                            :type="product.type"
                            :status="product.status"
                            :image="product.image"
                            :amount="product.amount"
                        />
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" :id="'modalEdit_' + id" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <form @submit.prevent="editOrder()">
                        <div class="modal-header">
                            <h5 class="modal-title">Pedido #{{ id }} - Editar</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Situação</label>
                                    <select v-model="orderEdit.status" class="form-select bg-white">
                                        <option selected disabled>Selecione</option>
                                        <option value="Aguardando">Aguardando</option>
                                        <option value="Atendendo">Atendendo</option>
                                        <option value="Separado">Separado</option>
                                        <option value="Enviando">Enviando</option>
                                        <option value="Cancelado">Cancelado</option>
                                        <option value="Concluído">Concluído</option>
                                    </select>
                                </div>
                                <div class="col-12 mb-3">
                                    <label class="form-label m-0 fw-bold">Comentários</label>
                                    <textarea v-model="orderEdit.comment" class="form-control" rows="3" style="resize: none"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button ref="btnCloseEdit" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button ref="btnEdit" type="submit" class="btn btn-primary">Editar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="modal fade" :id="'modalDelete_' + id" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
                <form @submit.prevent="deleteOrder()">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Pedido #{{ id }} - Excluir</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Você tem certeza que deseja excluir o pedido da(o) <b>{{ name }}</b> ? Este pedido tem o valor de <b>{{ totalPriceShow }}</b>
                        </div>
                        <div class="modal-footer">
                            <button ref="btnCloseDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            <button ref="btnDelete" type="submit" class="btn btn-danger">Excluir</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ProductOrder from '@/components/ProductOrder'
import {VMoney} from 'v-money'

export default {
    props: ['id', 'name', 'phone', 'cpf', 'email', 'products', 'price', 'code', 'street', 'number', 'complement', 'district', 'city', 'state', 'ship', 'shipPrice', 'shipDays', 'payment', 'total', 'status', 'comment', 'created_at'],

    components: {
        ProductOrder
    },

    data() {
        return {
            amount: 0,
            priceShow: this.price,
            shipPriceShow: this.shipPrice,
            totalPriceShow: this.total,
            dateShow: '',

            productsOrder: [],

            order: {
                status: this.status,
                comment: this.comment
            },

            orderEdit: {
                status: this.status,
                comment: this.comment
            },

            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },

    directives: {
        money: VMoney
    },

    methods: {
        productsAmount() {
            this.amount = 0
            this.products.forEach(product => {
                this.amount += product.amount
            })
        },

        async productsLoad() {
            const formData = new FormData()
            formData.append('products', JSON.stringify(this.products))

            this.productsOrder = []
            await this.$http.post('panel/orders/products', formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.productsOrder = response.data
            })
            .catch(() => {
                this.$parent.$parent.showAlert('error', 'Não foi possível carregar os produtos do pedido')
            })
        },

        async editOrder() {
            const formData = new FormData()
            formData.append('status', this.orderEdit.status)
            formData.append('comment', this.orderEdit.comment)

            this.$refs.btnEdit.innerText = 'Verificando...'
            this.$refs.btnEdit.disabled = true
            this.$parent.$parent.hideAlert()

            await this.$http.post('panel/orders/' + this.id + '/edit', formData, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    this.$parent.$parent.showAlert('success', response.data.message)
                    this.order.status = response.data.order.status
                    this.order.comment = response.data.order.comment
                    this.$refs.btnCloseEdit.click()
                }
                else
                {
                    this.$parent.$parent.showAlert('error', response.data.message)
                }
            })
            .catch(() => {
                this.$parent.$parent.showAlert('error', 'Não foi possível editar o pedido')
            })
            .finally(() => {
                this.$refs.btnEdit.innerText = 'Editar'
                this.$refs.btnEdit.disabled = false
            })
        },

         async deleteOrder() {
            this.$refs.btnDelete.innerText = 'Verificando...'
            this.$refs.btnDelete.disabled = true
            this.$parent.$parent.hideAlert()

            await this.$http.post('panel/orders/' + this.id + '/delete', '', {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                if(response.data.status == 'success') {
                    this.$parent.$parent.showAlert('success', response.data.message)
                    this.$refs.btnCloseDelete.click()

                    setTimeout(() => {
                        this.$parent.ordersLoad()
                    }, 1000)
                }
                else
                {
                    this.$parent.$parent.showAlert('error', response.data.message)
                }
            
            })
            .catch(() => {
                this.$parent.$parent.showAlert('error', 'Não foi possível excluir o pedido')
            })
            .finally(() => {
                this.$refs.btnDelete.innerText = 'Excluir'
                this.$refs.btnDelete.disabled = false
            })
        },

        formatDate() {
            const date = new Date(this.created_at)
            const day = new Intl.DateTimeFormat('pt-BR').format(date)
            const hour = new Intl.DateTimeFormat('pt-BR', {hour: 'numeric', minute: 'numeric', timeZone: 'America/Sao_paulo'}).format(date)
            this.dateShow = day + ' - ' + hour
        }
    },

    created() {
        this.productsLoad()
        this.productsAmount()
        this.formatDate()
    }
}
</script>

<style>
</style>