<template>
    <div class="container py-2 my-2">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mt-2">
                <li class="breadcrumb-item">
                    <router-link class="router-link text-dark" to="/">Inicio</router-link>
                </li>
                <li class="breadcrumb-item">
                    <router-link class="router-link text-dark" to="/painel">Painel</router-link>
                </li>
                <li class="breadcrumb-item">
                    Histórico
                </li>
            </ol>
        </nav>

        <table class="table bg-white rounded">
            <thead>
                <tr>
                    <th scope="col">Tipo</th>
                    <th scope="col">Descrição</th>
                </tr>
            </thead>
            <tbody>
                <LogTable v-for="(log, n) in logs"
                    :key="n"
                    :type="log.type"
                    :text="log.text"
                    :created_at="log.created_at"
                />
            </tbody>
        </table>

        <Pagination :data="pagination" alignment="center" previousText="Anterior" nextText="Próxima" @paginateTo="logsLoad"></Pagination>
    </div>
</template>

<script>
import LogTable from '@/components/LogTable'
import Pagination from 'advanced-laravel-vue-paginate'
import 'advanced-laravel-vue-paginate/dist/advanced-laravel-vue-paginate.css'

export default {
    components: {
        LogTable,
        Pagination,
    },

    data() {
        return {
            logs: [],

            pagination: {}
        }
    },

    methods: {
        async logsLoad(page) {
            if(typeof page === 'undefined') {
                page = 1;
            }

            this.logs = []
            this.$parent.loading = true
            
            await this.$http.get('panel/logs?page=' + page, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('_token')
                }
            })
            .then((response) => {
                this.pagination = response.data
                this.logs = response.data.data
                window.scrollTo(0, 0)
            })
            .catch(() => {
                this.$parent.showAlert('error', 'Não foi possível carregar os logs')
            })
            .finally(() => {
                this.$parent.loading = false
            })
        }
    },

    created() {
        this.logsLoad()
    }
}
</script>

<style>
</style>